<template>
  <div class="container">
    <!-- topimg -->
    <div class="pic">
      <div class="cirePic">
        <img
          class="animationClass"
          src="../../assets/images/wateMaxLoad.png"
          alt=""
        />
      </div>
    </div>
    <!-- title -->
    <div class="remTitle">
      <p>资金匹配中，请稍后...</p>
    </div>
    <!-- status -->
    <div class="status">
      <ul>
        <li>
          <div>
            <img src="../../assets/images/wateMinLoad.png" alt="" />
            <span>实名认证</span>
          </div>
        </li>
        <li>
          <div>
            <img src="../../assets/images/wateMinLoad.png" alt="" />
            <span>资产信息</span>
          </div>
        </li>
        <li>
          <div>
            <img src="../../assets/images/wateMinLoad.png" alt="" />
            <span>匹配成功</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import * as API_Common from "@/api/common.js";
export default {
  name: "MatchWaiting",
  data() {
    return {
      form: {
        channelNo: this.$route.query.source, // 渠道标识
        loanAmount: this.$route.query.amount ? this.$route.query.amount : 0, // 借款金额
      }, // 表单信息
      orderSn: this.$route.query.orderSn, // 订单编号
      brand: this.$route.query.brand, // 设备型号
      downPageUrl: this.$route.query.downPageUrl, // 进件结果下载页地址
      isRedirectProductsPage: parseInt(
        this.$route.query.isRedirectProductsPage
      ), // 标识是否是贷超流程的字段1是 0否
      isRedirectWorkWeixinPage: parseInt(
        this.$route.query.isRedirectWorkWeixinPage
      ), // 标识如果不是贷超流程需要用此字段进行判断是否跳转引导企业微信页面中，引导用户用企业微信扫码联系客服1是 0否
    };
  },
  created() {
    this.submitMatchApply();
  },
  methods: {
    // 提交匹配申请
    async submitMatchApply() {
      let datas = {
        orderSn: this.orderSn,
      };
      let res = await API_Common.matchProduct(datas);
      if (res?.code === 200) {
        let isMatchOk = res.data.hasMatchSuccess;
        let applyId = res.data.applyId;
        if (isMatchOk === 1) {
          // 匹配计划成功, 跳转到匹配中页面
          this.$router.push({
            name: "MatchResDetail",
            query: {
              source: this.form.channelNo,
              amount: this.form.loanAmount,
              brand: this.brand,
              isRedirectProductsPage: this.isRedirectProductsPage,
              isRedirectWorkWeixinPage: this.isRedirectWorkWeixinPage,
              downPageUrl: this.downPageUrl,
              orderSn: res.data.orderSn,
              applyId: applyId,
            },
          });
        } else {
          // 匹配计划失败, 跳转下个页面
          this.gotoNextPage(false);
        }
      } else {
        // 匹配接口异常, 跳转下个页面
        this.gotoNextPage(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  text-align: center;
  // 360度旋转动画
  .animationClass {
    transform: rotate(360deg);
  }
  .pic {
    display: flex;
    justify-content: center;
    margin-top: 0.8rem;
    width: 100%;
    text-align: center;
    .cirePic {
      // 除以50转rem
      position: relative;
      width: 2.4rem;
      height: 2.4rem;
      background: url("../../assets/images/wateLogoLoad.png") no-repeat center;
      background-size: cover;
      img {
        //margin-top: 5px;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 2.25rem;
        height: 2.25rem;
        transform: translate(-50%, -50%) rotate(0deg); /* Initial rotation */
        animation: rotate 3s linear infinite;
      }
      @keyframes rotate {
        from {
          transform: translate(-50%, -50%) rotate(0deg);
        }
        to {
          transform: translate(-50%, -50%) rotate(360deg);
        }
      }
    }
    .processDiv {
      width: 1.15rem;
      height: 0.6rem;
      font-size: 0.28rem;
      padding-left: 0.12rem;
      color: #fff;
      text-align: center;
      line-height: 0.6rem;
      background: url("../../assets/images/wateMateProcess.png") no-repeat;
      background-size: cover;
    }
  }
  .remTitle {
    text-align: center;
    p {
      margin-top: 26px;
      font-size: 0.28rem;
      color: #222;
    }
  }
  .status {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    ul {
      width: 4.8rem;
    }
    li {
      position: relative;
      margin-bottom: 20px;
      width: 100%;
      height: 0.8rem;
      font-size: 15px;
      text-align: left;
      background: #f4f4f4;
      border-radius: 10px;
      img {
        position: absolute;
        //margin-left: 20px;
        //margin-top: 0.24rem;
        top: 0.4rem;
        left: 0.4rem;
        // margin-right: 1.12rem;
        width: 0.34rem;
        height: 0.32rem;
        transform: translate(-50%, -50%) rotate(0deg); /* Initial rotation */
        animation: rotate2 3s linear infinite;
      }
      @keyframes rotate2 {
        from {
          transform: translate(-50%, -50%) rotate(0deg);
        }
        to {
          transform: translate(-50%, -50%) rotate(360deg);
        }
      }
      span {
        margin-left: 1.46rem + 0.2rem;
        font-size: 0.28rem;
        color: #202020;
        text-align: center;
        line-height: 0.8rem;
      }
    }
  }
}
</style>
